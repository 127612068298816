import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HEADER from './pages/common/HEADER';
import FOOTER from './pages/common/FOOTER';
import FMSW000 from './pages/FMSW000';
import FMSW010 from './pages/FMSW010';
import FMSW011 from './pages/FMSW011';
import FMSW012 from './pages/FMSW012';
import FMSW020 from './pages/FMSW020';
import FMSW021 from './pages/FMSW021';
import FMSW030 from './pages/FMSW030';
import FMSW031 from './pages/FMSW031';
import FMSW040 from './pages/FMSW040';
import './App.css';

function App() {
  const { user, logout, loginWithRedirect, isAuthenticated, isLoading } =
    useAuth0();

  // 認証処理後のリダイレクト時に認証結果が反映されるまでの間isLoadingがtrueとなる。
  if (isLoading) {
    return (
      <>
        <header id="head"></header>
        <div id="main">Loading...</div>;
        <footer id="foot">
          <Router>
            <Routes>
              <Route path="/*" element={<FOOTER />} />
            </Routes>
          </Router>
        </footer>
      </>
    );
  }
  // 認証済みか判定し、認証前であればAuth0の認証画面に遷移
  if (isAuthenticated && user?.app_metadata?.maritime_app?.role) {
    return (
      <>
        <Router>
          <header id="head">
            <div>
              <Routes>
                <Route path="/" element={<FMSW000Name />} />
                <Route path="/FMSW010" element={<FMSW010Name />} />
                <Route path="/FMSW011" element={<FMSW010Name />} />
                <Route path="/FMSW012" element={<FMSW010Name />} />
                <Route path="/FMSW020" element={<FMSW020Name />} />
                <Route path="/FMSW021" element={<FMSW020Name />} />
                <Route path="/FMSW030" element={<FMSW030Name />} />
                <Route path="/FMSW031" element={<FMSW030Name />} />
                <Route path="/FMSW040" element={<FMSW040Name />} />
              </Routes>
            </div>

            <Routes>
              <Route path="/*" element={<HEADER />} />
            </Routes>
          </header>

          <div id="main">
            <Routes>
              <Route path="/" element={<FMSW000 />} />
              <Route path="/FMSW010" element={<FMSW010 />} />
              <Route path="/FMSW011" element={<FMSW011 />} />
              <Route path="/FMSW012" element={<FMSW012 />} />
              <Route path="/FMSW020" element={<FMSW020 />} />
              <Route path="/FMSW021" element={<FMSW021 />} />
              <Route path="/FMSW030" element={<FMSW030 />} />
              <Route path="/FMSW031" element={<FMSW031 />} />
              <Route path="/FMSW040" element={<FMSW040 />} />
            </Routes>
          </div>

          <footer id="foot">
            <Routes>
              <Route path="/*" element={<FOOTER />} />
            </Routes>
          </footer>
        </Router>
      </>
    );
  }
  // 認証に成功したがアプリの参照権限がない場合はエラーメッセージを表示
  else if (isAuthenticated) {
    return (
      <>
        <header id="head"></header>
        <div id="main">
          <p>The authentication check failed.</p>
          <div
            style={{
              marginTop: '10px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <button
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              title="OK"
            >
              OK
            </button>
          </div>
        </div>

        <footer id="foot">
          <Router>
            <Routes>
              <Route path="/" element={<FOOTER />} />
            </Routes>
          </Router>
        </footer>
      </>
    );
  }
  // 認証前のためログイン画面に遷移
  else {
    // Auth0認証画面へ遷移
    return loginWithRedirect();
  }
}
export default App;

const FMSW000Name = () => {
  return <div id="window-name">Menu</div>;
};

const FMSW010Name = () => {
  return <div id="window-name">Noon Report</div>;
};

const FMSW020Name = () => {
  return <div id="window-name">ABLOG</div>;
};

const FMSW030Name = () => {
  return <div id="window-name">Arrival / Departure Infomation</div>;
};

const FMSW040Name = () => {
  return <div id="window-name">Data Recive Status</div>;
};
