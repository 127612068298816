import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Subtitle from '../components/Subtitle';
import ReturnButton from '../components/ReturnButton';
import ArrDepMetaData from '../components/ArrDepMetaData';
import ArrDepCustomData from '../components/ArrDepCustomData';

const FMSW031 = () => {
  const [arrDepDetailData, setArrDepDetailData] = useState({});

  const location = useLocation();
  const { arrDepId, historyId, officer, isFromABLOG } = location?.state ?? {};

  useEffect(() => {
    const conds = {
      imo_no: sessionStorage.getItem('selectedShip'),
      arr_dep_id: arrDepId,
      history_id: historyId,
      officer,
    };
    axios
      .post(window.location.origin + '/api/FMSW030/getArrDepDetailData', conds)
      .then((res) => {
        setArrDepDetailData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [arrDepId, historyId, officer]);

  return (
    <>
      <Helmet>
        <title>seawise - Arrival / Departure</title>
      </Helmet>
      <div className="sub-header">
        <Subtitle
          title={'View Arrival / Departure Information'}
          officer={officer}
        />
        {isFromABLOG ? (
          <ReturnButton
            url="/FMSW021"
            params={JSON.parse(sessionStorage.getItem('ABLOGConds'))}
          />
        ) : (
          <ReturnButton url="/FMSW030" params={{ prevOfficer: officer }} />
        )}
      </div>
      <ArrDepMetaData metaData={arrDepDetailData?.meta_data} />
      <ArrDepCustomData customData={arrDepDetailData?.custom_data} />
    </>
  );
};

export default FMSW031;
