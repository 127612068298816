const OfficerRadioButton = ({ officer, setOfficer }) => {
  const radioCollection = ['DECK', 'ENG'];
  return (
    <div className="officer-radio">
      {radioCollection.map((value) => {
        return (
          <label key={value}>
            <input
              type="radio"
              value={value}
              checked={officer === value}
              onChange={() => setOfficer(value)}
            />
            {value}
          </label>
        );
      })}
    </div>
  );
};

export default OfficerRadioButton;
