import MetaDataArea from './MetaDataArea';

const ArrDepMetaData = ({ metaData }) => {
  const timediffPortColumn = metaData?.timediff_port
    ? [
        {
          title: 'Time Difference UTC',
          field: 'timediff_port',
        },
      ]
    : [];

  const columns = [
    [{ title: 'Date & Time', field: 'date_and_time' }, ...timediffPortColumn],
    [{ title: 'Port Name', field: 'port_name' }],
    [{ title: 'Departure / Arrival', field: 'departure_arrival' }],
  ];

  return <MetaDataArea columns={columns} metaData={metaData} />;
};

export default ArrDepMetaData;
