const UpdateDateTableHeader = ({ isUpdateSort, sortTable }) => {
  return (
    <th
      style={{
        whiteSpace: 'pre-wrap',
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
      onClick={() => sortTable(!isUpdateSort)}
    >
      Update Date
      <br />
      (Sort {isUpdateSort ? 'ON' : 'OFF'})
    </th>
  );
};

export default UpdateDateTableHeader;
