import MetaDataArea from './MetaDataArea';

const NoonMetaData = ({ metaData, officer }) => {
  const columns =
    officer == 'DECK'
      ? [
          [
            { title: 'Date', field: 'date' },
            { title: 'GMT', field: 'gmt' },
          ],
          [
            { title: 'Position at Noon(Lat.)', field: 'lat' },
            { title: 'Position at Noon(Long.)', field: 'lon' },
          ],
          [{ title: "Clock's -ah'd + ab'k", field: 'clock' }],
          [{ title: 'Remarks', field: 'remarks' }],
        ]
      : [
          [{ title: 'Date', field: 'date' }],
          [{ title: 'Sailing Mode', field: 'sailing_mode' }],
          [{ title: 'Hours', field: 'hours' }],
          [{ title: 'Remarks', field: 'remarks' }],
        ];

  return <MetaDataArea columns={columns} metaData={metaData} />;
};

export default NoonMetaData;
