import ReactDatePicker from './ReactDatePicker';

const SearchInterval = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setIsSearch,
}) => {
  return (
    <div className="search-interval">
      <label>Search Interval</label>
      <ReactDatePicker
        selectedDate={startDate}
        setSelectedDate={setStartDate}
        placeholder={'YYYY-MM-dd(From)'}
      />
      <span>{'～'}</span>
      <ReactDatePicker
        selectedDate={endDate}
        setSelectedDate={setEndDate}
        placeholder={'YYYY-MM-dd(To)'}
      />
      <button
        className="search-interval-btn"
        onClick={() => setIsSearch((prev) => !prev)}
        title="Search"
      >
        Search
      </button>
    </div>
  );
};

export default SearchInterval;
