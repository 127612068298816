import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Subtitle from '../components/Subtitle';
import ReturnButton from '../components/ReturnButton';
import SearchInterval from '../components/SearchInterval';
import NoonDetailListTable from '../components/NoonDetailListTable';

const FMSW012 = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [noonDetailList, setNoonDetailList] = useState([]);

  const location = useLocation();
  const { officer, yearMonth } = location?.state ?? {};

  useEffect(() => {
    if (
      Object.prototype.toString.call(startDate) === '[object Date]' &&
      Object.prototype.toString.call(endDate) === '[object Date]'
    ) {
      const conds = {
        imo_no: sessionStorage.getItem('selectedShip'),
        officer: officer,
        interval_from: startDate.toLocaleString('ja-JP', {
          timeZone: 'Asia/Tokyo',
        }),
        interval_to: endDate.toLocaleString('ja-JP', {
          timeZone: 'Asia/Tokyo',
        }),
      };
      axios
        .post(window.location.origin + '/api/FMSW010/getNoonDetailList', conds)
        .then((res) => {
          setNoonDetailList(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isSearch]);

  return (
    <>
      <Helmet>
        <title>seawise - Noon Report</title>
      </Helmet>
      <div className="sub-header">
        <Subtitle title={'List of Noon Report Detail Data'} officer={officer} />
        <ReturnButton
          url="/FMSW010"
          params={{ prevOfficer: officer, prevYearMonth: yearMonth }}
        />
      </div>
      <SearchInterval
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setIsSearch={setIsSearch}
      />
      {noonDetailList?.map((noonDetailData, i) => {
        return (
          <NoonDetailListTable
            key={i}
            noonDetailList={noonDetailData}
            officer={officer}
          />
        );
      })}
    </>
  );
};

export default FMSW012;
