import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Subtitle from '../components/Subtitle';
import ReturnMenuButton from '../components/ReturnMenuButton';
import OfficerRadioButton from '../components/OfficerRadioButton';
import AblogDataTable from '../components/AblogDataTable';

const FMSW020 = () => {
  const location = useLocation();
  const { prevOfficer = 'DECK' } = location.state ?? {};

  const [ablogData, setAblogData] = useState([]);
  const [officer, setOfficer] = useState(prevOfficer);
  const [isUpdateSort, setIsUpdateSort] = useState(false);

  useEffect(() => {
    const conds = {
      imo_no: sessionStorage.getItem('selectedShip'),
      officer: officer,
    };
    axios
      .post(window.location.origin + '/api/FMSW020/getABLOGData', conds)
      .then((res) => {
        setAblogData(res.data);
        setIsUpdateSort(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [officer]);

  const sortTable = (changeUpdateSort) => {
    let tableData = [];
    if (changeUpdateSort) {
      // デフォルト => Update Date
      tableData = ablogData.sort((a, b) => {
        if (b.update_date !== a.update_date) {
          // Sort by Update Date
          return (
            new Date(b.update_date).getTime() -
            new Date(a.update_date).getTime()
          );
        } else if (b.ablog_id !== a.ablog_id) {
          // Sort by ABLOG ID
          return b.ablog_id - a.ablog_id;
        } else if (b.history_id !== a.history_id) {
          // Sort by 履歴ID
          return b.history_id - a.history_id;
        }
      });
    } else {
      // Update Date => デフォルト
      tableData = ablogData.sort((a, b) => {
        if (b.create_date_user !== a.create_date_user) {
          return (
            // Sort by Create Date(User)
            new Date(b.create_date_user).getTime() -
            new Date(a.create_date_user).getTime()
          );
        } else if (b.create_date_system !== a.create_date_system) {
          return (
            // Sort by Create Date (System)
            new Date(b.create_date_system).getTime() -
            new Date(a.create_date_system).getTime()
          );
        } else if (b.ablog_id !== a.ablog_id) {
          // Sort by ABLOG ID
          return b.ablog_id - a.ablog_id;
        } else if (b.history_id !== a.history_id) {
          // Sort by 履歴ID
          return b.history_id - a.history_id;
        }
      });
    }
    setAblogData(tableData);
    setIsUpdateSort((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>seawise - ABLOG</title>
      </Helmet>
      <div className="sub-header">
        <Subtitle title={'List of ABLOG Data'} officer={officer} />
        <ReturnMenuButton />
      </div>
      <OfficerRadioButton officer={officer} setOfficer={setOfficer} />
      <AblogDataTable
        ablogData={ablogData}
        sortTable={sortTable}
        isUpdateSort={isUpdateSort}
        officer={officer}
      />
    </>
  );
};

export default FMSW020;
