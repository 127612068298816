const CompanySelectBox = ({ shipCompanyList, company, setCompany}) => {
    return (
        <div>
            <label>Company</label>
            <select
                className='select-box'
                id="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                style={{ padding: '8px', margin: '8px' }}
            >
                <option value="">All Selected</option>
                {shipCompanyList?.map((selectData) => (
                    <option key={selectData.shipcompany_id} value={selectData.shipcompany_id}>
                            {selectData.shipcompany_name}
                    </option>
                ))}
            </select>
        </div>
    );
  };
  
  export default CompanySelectBox;
  