import { useNavigate } from 'react-router-dom';

const DisplayDetailButton = ({ url, params }) => {
  const navigate = useNavigate();

  const jumpLink = () => {
    navigate(url, { state: params });
  };

  return (
    <button
      className="display-detail-btn"
      onClick={jumpLink}
      title="Display Detail List"
    >
      Display Detail List
    </button>
  );
};

export default DisplayDetailButton;
