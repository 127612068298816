import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './HEADER.css';
import axios from 'axios';

const HEADER = () => {
  const { logout } = useAuth0();
  return (
    <div id="header-main">
      <div id="shipInfo-item">
        <Routes>
          <Route path="/" element={<SelectShipList />} />
          <Route path="/FMSW010" element={<LabelShip />} />
          <Route path="/FMSW011" element={<LabelShip />} />
          <Route path="/FMSW012" element={<LabelShip />} />
          <Route path="/FMSW020" element={<LabelShip />} />
          <Route path="/FMSW021" element={<LabelShip />} />
          <Route path="/FMSW030" element={<LabelShip />} />
          <Route path="/FMSW031" element={<LabelShip />} />
        </Routes>
      </div>
      <div id="logout-item">
        <button id="logout-button" onClick={() => ClickLogout()} title="Logout">
          Logout
        </button>
      </div>
    </div>
  );

  function ClickLogout() {
    // セッション情報削除
    sessionStorage.removeItem('selectedShip');
    sessionStorage.removeItem('selectedName');
    logout({ logoutParams: { returnTo: window.location.origin } });
    // ログアウト後のブラウザバック対策
    window.history.pushState(null, '', window.location.origin);
  }
};
export default HEADER;

// メニュー画面の場合は船舶名を一覧表示
const SelectShipList = () => {
  const { user } = useAuth0();
  const [shipList, setShipList] = useState([]);
  const [ship, setShip] = useState();

  useEffect(() => {
    // ユーザー情報を元にAPIにて船舶一覧を取得する
    const conds = {
      organization_id: user.app_metadata.organization_id.toString(),
    };
    axios
      .post(
        window.location.origin + '/api/FMSW000/getVesselsForOrganization',
        conds
      )
      .then((res) => {
        setShipList(res.data);

        // sesionStorageに未保存の場合は保存
        const selectedShip = sessionStorage.getItem('selectedShip');
        const filterShipData = res.data.filter((e) => e.imo_no == selectedShip);
        if (selectedShip && filterShipData.length) {
          setShip(filterShipData[0]);
        } else {
          setShip(res.data[0]);
          sessionStorage.setItem('selectedShip', res.data[0].imo_no);
          sessionStorage.setItem('selectedName', res.data[0].ship_name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  // 船選択変更
  function changeShip(imoNo) {
    const changedShip =
      shipList.filter((ship) => {
        return ship.imo_no == imoNo;
      })[0] ?? null;

    setShip(changedShip);
    sessionStorage.setItem('selectedShip', changedShip.imo_no);
    sessionStorage.setItem('selectedName', changedShip.ship_name);
  }

  return (
    <div id="shipInfo-item">
      Ship Name
      <select
        className="select-ship"
        value={ship?.imo_no}
        onChange={(e) => changeShip(e.target.value)}
      >
        {shipList.map((e) => {
          return (
            <option key={e.imo_no} value={e.imo_no}>
              {e.ship_name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

// メニュー画面以外は選択した船舶をテキスト表示
const LabelShip = () => {
  const selectedName = sessionStorage.getItem('selectedName');
  return (
    <div>
      <label style={{ fontSize: '18px' }}>
        Ship Name{' : '}
        <span style={{ fontWeight: 'bold' }}>{selectedName ?? ''}</span>
      </label>
    </div>
  );
};
