import ViewButton from './ViewButton';
import { format } from 'date-fns';

const AblogLinkArrDepData = ({ linkArrDepTableData, officer }) => {
  const columns = [
    {
      title: 'Date & Time',
      field: 'date_and_time',
      width: '30%',
    },
    {
      title: 'Port Name',
      field: 'port_name',
      width: '27%',
    },
    {
      title: 'Arrival /\nDeparture',
      field: 'departure_arrival',
      width: '20%',
    },
    {
      title: 'View',
      field: 'view',
      width: '23%',
    },
  ];

  return (
    <div className="child-groupbox-container">
      <div className="groupbox-title">
        <h5 className="groupbox-label">Linked Arrival Departure Information</h5>
      </div>
      <div className="childgroupbox-content ">
        <div className="table-area">
          <table className="link-info-table">
            <thead>
              <tr>
                {columns.map((column) => {
                  return (
                    <th
                      key={column.field}
                      style={{ whiteSpace: 'pre-wrap', width: column.width }}
                    >
                      {column.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {linkArrDepTableData?.map((arrDepRowData, i) => {
                return (
                  <tr key={i}>
                    {columns.map((column) => {
                      return column.field == 'view' ? (
                        <td key={column.field}>
                          <ViewButton
                            url={'/FMSW031'}
                            params={{
                              arrDepId: arrDepRowData.arrival_departure_id,
                              historyId: arrDepRowData.history_id,
                              officer,
                              isFromABLOG: true,
                            }}
                          />
                        </td>
                      ) : column.field == 'date_and_time' ? (
                        arrDepRowData[column.field] != null ? (
                          <td key={column.field}>
                            {format(
                              arrDepRowData[column.field],
                              'yyyy-MM-dd HH:mm'
                            )}
                          </td>
                        ) : (
                          <td key={column.field}>{''}</td>
                        )
                      ) : (
                        <td key={column.field}>
                          {arrDepRowData[column.field]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AblogLinkArrDepData;
