import { useNavigate } from 'react-router-dom';

const ReturnButton = ({ url = '/', params = {} }) => {
  const navigate = useNavigate();

  const jumpLink = () => {
    navigate(url, { state: params });
  };

  return (
    <button className="return-btn" onClick={jumpLink} title="RETURN">
      ◁ RETURN
    </button>
  );
};

export default ReturnButton;
