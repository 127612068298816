const ReturnMenuButton = ({ url = '/' }) => {
  return (
    <button
      className="return-btn"
      onClick={() => navigation.navigate(url)}
      title="RETURN"
    >
      ◁ RETURN
    </button>
  );
};

export default ReturnMenuButton;
