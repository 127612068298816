import { useNavigate } from 'react-router-dom';

const ViewButton = ({ url, params }) => {
  const navigate = useNavigate();

  const jumpLink = () => {
    navigate(url, { state: params });
  };

  return (
    <button className="view-btn" onClick={jumpLink} title="View">
      View
    </button>
  );
};

export default ViewButton;
