import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ReactDatePicker = ({ selectedDate, setSelectedDate, placeholder }) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      dateFormat={'yyyy-MM-dd'}
      placeholderText={placeholder}
    />
  );
};

export default ReactDatePicker;
