import CustomDataCategoryArea from './CustomDataCategoryArea';

const CustomDataArea = ({ customData }) => {
  return (
    <div className="child-row groupbox-container">
      <div className="groupbox-title">
        <h5 className="groupbox-label">Custom Data</h5>
      </div>
      <div className="groupbox-content-parent">
        <div className="groupbox-content">
          {customData?.map((categoryData) => {
            return (
              <CustomDataCategoryArea
                key={categoryData.category_name}
                categoryName={categoryData.category_name}
                itemList={categoryData.item_list}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomDataArea;
