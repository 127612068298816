import ViewButton from './ViewButton';
import { format } from 'date-fns';

const AblogLinkNoonReportDeck = ({ linkNoonReportData, officer }) => {
  const columns = [
    {
      title: 'Date',
      field: 'date',
      width: '24%',
    },
    {
      title: 'Latitude',
      field: 'positionatnoon_lat',
      width: '26%',
    },
    {
      title: 'Longitude',
      field: 'positionatnoon_long',
      width: '26%',
    },
    {
      title: 'View',
      field: 'view',
      width: '24%',
    },
  ];

  return (
    <div className="table-area">
      <table className="link-info-table">
        <thead>
          <tr>
            {columns.map((column) => {
              return (
                <th
                  key={column.field}
                  style={{ whiteSpace: 'pre-wrap', width: column.width }}
                >
                  {column.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {linkNoonReportData?.map((noonReportRowData, i) => {
            return (
              <tr key={i}>
                {columns.map((column) => {
                  return column.field == 'view' ? (
                    <td key={column.field}>
                      <ViewButton
                        url={'/FMSW011'}
                        params={{
                          noonId: noonReportRowData.noonreport_id,
                          historyId: noonReportRowData.history_id,
                          officer,
                          isFromABLOG: true,
                        }}
                      />
                    </td>
                  ) : column.field == 'date' ? (
                    noonReportRowData[column.field] != null ? (
                      <td key={column.field}>
                        {format(noonReportRowData[column.field], 'yyyy-MM-dd')}
                      </td>
                    ) : (
                      <td key={column.field}>{''}</td>
                    )
                  ) : (
                    <td key={column.field}>
                      {noonReportRowData[column.field]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AblogLinkNoonReportDeck;
