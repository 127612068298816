import AblogMetaArea from './AblogMetaArea';

const AblogMetaData = ({ metaData }) => {
  const columns = [
    [{ title: 'Ship Name', field: 'ship_name' }],
    [{ title: 'Voyage No.', field: 'voyage_no' }],
    [{ title: 'Port From', field: 'port_from' },{ title: 'Port To', field: 'port_to' }],
    [{ title: "Com'ced Voyage Date & Time", field: 'date_from' },{ title: 'Completed Voyage Date & Time', field: 'date_to' }],
    [{ title: 'Total Hour', field: 'total_hour' }],
    [{ title: 'Mater', field: 'master' },{ title: 'Chief Officer', field: 'chief_officer' }],
    [{ title: 'Creation Date', field: 'create_date_user' }],
    [{ title: 'Remarks', field: 'remarks' }],
  ];

  return <AblogMetaArea columns={columns} metaData={metaData} />;
};

export default AblogMetaData;
