const AlarmRadioButton = ({ alarm, setAlarm}) => {
    const radioCollection = ['All', 'Alarm Only','Not Alarm'];
    return (
      <div className="alarm-radio">
        {radioCollection.map((value) => {
          return (
            <label key={value} style={{ display: 'block', marginBottom: '8px' }}>
              <input
                type="radio"
                value={value}
                checked={alarm === value}
                onChange={() => setAlarm(value)}
                style={{ marginRight: '8px' }}
              />
              {value}
            </label>
          );
        })}
      </div>
    );
  };
  
  export default AlarmRadioButton;
  