import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Subtitle from '../components/Subtitle';
import ReturnMenuButton from '../components/ReturnMenuButton';
import OfficerRadioButton from '../components/OfficerRadioButton';
import NoonDataTable from '../components/NoonDataTable';
import DisplayDetailButton from '../components/DisplayDetailButton';

const FMSW010 = () => {
  const location = useLocation();
  const {
    prevOfficer = 'DECK',
    prevYearMonth = () => {
      const nowDate = new Date();
      return `${nowDate.getFullYear()}/${String(nowDate.getMonth() + 1).padStart(2, '0')}`;
    },
  } = location?.state ?? {};

  const [noonData, setNoonData] = useState([]);
  const [officer, setOfficer] = useState(prevOfficer);
  const [isUpdateSort, setIsUpdateSort] = useState(false);
  const [yearMonth, setYearMonth] = useState(prevYearMonth);

  useEffect(() => {
    const conds = {
      imo_no: sessionStorage.getItem('selectedShip'),
      officer: officer,
      year_month: yearMonth,
    };
    axios
      .post(window.location.origin + '/api/FMSW010/getNoonData', conds)
      .then((res) => {
        setNoonData(res.data);
        setIsUpdateSort(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [officer, yearMonth]);

  const sortTable = (changedUpdateSort) => {
    let tableData = [];
    if (changedUpdateSort) {
      // デフォルト => Update Date
      tableData = noonData.sort((a, b) => {
        if (b.update_date !== a.update_date) {
          return (
            // Sort by Update Date
            new Date(b.update_date).getTime() -
            new Date(a.update_date).getTime()
          );
        } else if (b.noonreport_id !== a.noonreport_id) {
          // Sort by Noon Report ID
          return b.noonreport_id - a.noonreport_id;
        } else if (b.history_id !== a.history_id) {
          // Sort by History ID
          return b.history_id - a.history_id;
        }
      });
    } else {
      // Update Date => デフォルト
      tableData = noonData.sort((a, b) => {
        if (b.gmt !== a.gmt) {
          return (
            // Sort by GMT
            new Date(b.gmt).getTime() - new Date(a.gmt).getTime()
          );
        } else if (b.date !== a.date) {
          return (
            // Sort by Date
            new Date(b.date).getTime() - new Date(a.date).getTime()
          );
        } else if (b.create_date_system !== a.create_date_system) {
          return (
            // Sort by Create Date (System)
            new Date(b.create_date_system).getTime() -
            new Date(a.create_date_system).getTime()
          );
        } else if (b.noonreport_id !== a.noonreport_id) {
          // Sort by Noon Report ID
          return b.noonreport_id - a.noonreport_id;
        } else if (b.ablog_id !== a.ablog_id) {
          // Sort by History ID
          return b.ablog_id - a.ablog_id;
        }
      });
    }

    setNoonData(tableData);
    setIsUpdateSort((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>seawise - Noon Report</title>
      </Helmet>
      <div className="sub-header">
        <Subtitle title={'List of Noon Report'} officer={officer} />
        <ReturnMenuButton />
      </div>
      <OfficerRadioButton officer={officer} setOfficer={setOfficer} />
      <NoonDataTable
        noonData={noonData}
        sortTable={sortTable}
        isUpdateSort={isUpdateSort}
        yearMonth={yearMonth}
        setYearMonth={setYearMonth}
        officer={officer}
      />
      <DisplayDetailButton url={'/FMSW012'} params={{ officer, yearMonth }} />
    </>
  );
};

export default FMSW010;
