import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Subtitle from '../components/Subtitle';
import ReturnButton from '../components/ReturnButton';
import NoonMetaData from '../components/NoonMetaData';
import NoonCustomData from '../components/NoonCustomData';
import DownloadButton from '../components/DownloadButton';

const FMSW011 = () => {
  const [noonDetailData, setNoonDetailData] = useState({});
  const [hasDownloadFile, setHasDownloadFile] = useState(false);

  const location = useLocation();
  const { noonId, historyId, officer, yearMonth, isFromABLOG } =
    location?.state ?? {};

  useEffect(() => {
    const conds = {
      imo_no: sessionStorage.getItem('selectedShip'),
      noon_id: noonId,
      history_id: historyId,
      officer,
    };
    axios
      .post(window.location.origin + '/api/FMSW010/getNoonDetailData', conds)
      .then((res) => {
        setNoonDetailData(res.data);
        setHasDownloadFile(!!res.data.meta_data.noonreport_template_file);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [noonId, historyId, officer]);

  const getdownloadNoonData = () => {
    const conds = {
      imo_no: sessionStorage.getItem('selectedShip'),
      noon_id: noonId,
      history_id: historyId,
      officer,
    };
    axios
      .post(window.location.origin + '/api/FMSW010/downloadNoonData', conds)
      .then((res) => {
        const { file_path, file_name } = res.data;
        if (file_path && file_name) {
          return downloadFile(file_name, file_path);
        } else {
          alert('There is no download file.');
        }
      })
      .catch(() => {
        alert('Please download Noon Report by using ABLOG function with ABLOG');
      });
  };

  const downloadFile = async (fileName, fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const newBlob = new Blob([blob]);
      const objUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = objUrl;
      link.download = fileName;
      link.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(objUrl);
      }, 250);
    } catch (error) {
      console.log('Download failed:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>seawise - Noon Report</title>
      </Helmet>
      <div className="sub-header">
        <Subtitle title={'View Noon Report'} officer={officer} />
        {isFromABLOG ? (
          <ReturnButton
            url="/FMSW021"
            params={JSON.parse(sessionStorage.getItem('ABLOGConds'))}
          />
        ) : (
          <ReturnButton
            url="/FMSW010"
            params={{ prevOfficer: officer, prevYearMonth: yearMonth }}
          />
        )}
      </div>
      <NoonMetaData metaData={noonDetailData?.meta_data} officer={officer} />
      <NoonCustomData customData={noonDetailData?.custom_data} />
      {hasDownloadFile && (
        <DownloadButton
          ButtonName={'Download'}
          handleClick={getdownloadNoonData}
        />
      )}
    </>
  );
};

export default FMSW011;
