import AblogLinkedArrDepData from './AblogLinkedArrDepData';
import AblogLinkedNoonReportData from './AblogLinkedNoonReportData';

const AblogLinkedArea = ({ arrDepData, noonReportData,officer }) => {
  return(
    <div className='link-info-row'> 
        <AblogLinkedArrDepData linkArrDepTableData={arrDepData} officer={officer} />
        <AblogLinkedNoonReportData linkNoonReportData={noonReportData} officer={officer} />
    </div>
  )
};

export default AblogLinkedArea;
