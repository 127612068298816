import UpdateDateTableHeader from './UpdateDateTableHeader';
import ViewButton from './ViewButton';
import { format } from 'date-fns';

const AblogDataTable = ({ ablogData, sortTable, isUpdateSort, officer }) => {
  const columns = [
    {
      title: 'Voyage No.',
      field: 'voyage_no',
    },
    {
      title: 'Port From',
      field: 'port_from',
    },
    {
      title: "Com'ced Voyage \nDate & Time",
      field: 'date_from',
    },
    {
      title: 'Port To',
      field: 'port_to',
    },
    {
      title: 'Completed Voyage \nDate & Time',
      field: 'date_to',
    },
    {
      title: 'Update Date \n(Sort OFF)',
      field: 'update_date',
    },
    {
      title: 'View',
      field: 'view',
    },
  ];

  return (
    <div className="table-area">
      <table className="list-table">
        <thead>
          <tr>
            {columns.map((column) => {
              return column.field == 'update_date' ? (
                <UpdateDateTableHeader
                  isUpdateSort={isUpdateSort}
                  sortTable={sortTable}
                  key={column.field}
                />
              ) : (
                <th key={column.field} style={{ whiteSpace: 'pre-wrap' }}>
                  {column.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {ablogData?.map((rowData, i) => {
            return (
              <tr key={i}>
                {columns.map((column) => {
                  return column.field == 'view' ? (
                    <td key={column.field}>
                      <ViewButton
                        url={'/FMSW021'}
                        params={{
                          ablogId: rowData.ablog_id,
                          historyId: rowData.history_id,
                          officer,
                        }}
                      />
                    </td>
                  ) : column.field == 'date_from' ||
                    column.field == 'date_to' ||
                    column.field == 'update_date' ? (
                    rowData[column.field] != null ? (
                      <td key={column.field}>
                        {format(rowData[column.field], 'yyyy-MM-dd HH:mm')}
                      </td>
                    ) : (
                      <td key={column.field}>{''}</td>
                    )
                  ) : (
                    <td key={column.field}>{rowData[column.field]}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AblogDataTable;
