import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet-async';

const FMSW000 = () => {
  const { user } = useAuth0();

  // 画面遷移前に選択した船舶情報が取得できるかチェック
  function CheckSelectShip(windowName) {
    const selectedShip = sessionStorage.getItem('selectedShip');
    if (!selectedShip || selectedShip == '') {
      alert('Please select a ship.');
      return false;
    }
    navigation.navigate(windowName);
  }

  return (
    <>
      <Helmet>
        <title>seawise - Menu</title>
      </Helmet>
      <button
        className="navigation-button"
        onClick={() => CheckSelectShip('FMSW020')}
        title="ABLOG"
      >
        ABLOG
      </button>
      <br />
      <button
        className="navigation-button"
        onClick={() => CheckSelectShip('FMSW030')}
        title="Arrival / Departure Information"
      >
        Arrival / Departure Information
      </button>
      <br />
      <button
        className="navigation-button"
        onClick={() => CheckSelectShip('FMSW010')}
        title="Noon Report"
      >
        Noon Report
      </button>
      <br />
      {(() => {
        if (user?.app_metadata?.maritime_app.role == 'admin') {
          return (
            <button
              className="navigation-button"
              onClick={() => navigation.navigate('FMSW040')}
              title="Data Receive Status"
            >
              Data Receive Status
            </button>
          );
        }
      })()}
    </>
  );
};

export default FMSW000;
