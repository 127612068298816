import React from 'react';

const CustomDataCategoryArea = ({ categoryName, itemList }) => {
  // 5項目ずつに整形
  const tableData = [];
  for (let i = 0; i < itemList.length; i += 5) {
    tableData.push(itemList.slice(i, i + 5));
  }

  return (
    <div key={categoryName}>
      <label>{categoryName}</label>
      <table className="child-table">
        {tableData.map((rowData, i) => {
          return (
            <React.Fragment key={i}>
              <thead>
                <tr>
                  <th>{rowData[0]?.name}</th>
                  <th>{rowData[1]?.name}</th>
                  <th>{rowData[2]?.name}</th>
                  <th>{rowData[3]?.name}</th>
                  <th>{rowData[4]?.name}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{rowData[0]?.value}</td>
                  <td>{rowData[1]?.value}</td>
                  <td>{rowData[2]?.value}</td>
                  <td>{rowData[3]?.value}</td>
                  <td>{rowData[4]?.value}</td>
                </tr>
              </tbody>
            </React.Fragment>
          );
        })}
      </table>
    </div>
  );
};

export default CustomDataCategoryArea;
