import React from 'react';

const MetaDataArea = ({ columns, metaData }) => {
  return (
    <div>
      {columns?.map((row, i) => {
        const item = row.map((column) => {
          return (
            <React.Fragment key={column.field}>
              <label className="meta-label">{column.title} :</label>
              {column.field == 'remarks' && metaData != null ? (
                <label style={{ whiteSpace: 'pre-wrap' }}>
                  {metaData[column.field]}
                </label>
              ) : metaData != null ? (
                <label>{metaData[column.field]}</label>
              ) : (
                <label>{''}</label>
              )}
            </React.Fragment>
          );
        });

        return (
          <div
            key={i}
            className="meta-row"
            style={
              row.length == 1
                ? {
                    overflow: 'auto',
                  }
                : {}
            }
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default MetaDataArea;
