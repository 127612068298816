const SelectYearMonthList = ({ options = [], yearMonth, setYearMonth }) => {
  return (
    <select value={yearMonth} onChange={(e) => setYearMonth(e.target.value)}>
      {options.map((opt) => {
        return (
          <option key={opt} value={opt}>
            {opt}
          </option>
        );
      })}
    </select>
  );
};

export default SelectYearMonthList;
