const SearchButton = ({ setIsSearched }) => {
  return (
    <button
      className="srup-btn "
      style={{ width: '160px' }}
      onClick={() => setIsSearched((prev) => !prev)}
      title="Search"
    >
      Search
    </button>
  );
};

export default SearchButton;
