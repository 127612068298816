const ShipSelectBox = ({ shipList,ship,setShip}) => {
      return (
          <div>
              <label>Ship</label>
              <select
                  className='select-box'
                  id="ship"
                  value={ship}
                  onChange={(e) => setShip(e.target.value)}
                  style={{ padding: '8px', margin: '8px' }}
              >
                <option value="">All Selected</option>
                {shipList?.map((selectData) => (
                    <option key={selectData.imo_no} value={selectData.imo_no}>
                            {selectData.ship_name}
                    </option>
                ))}
              </select>
          </div>
      );
    };
    
    export default ShipSelectBox;
    