import './FOOTER.css';

const FOOTER = () => {
  return (
    <div id="footer-main">
      Copyright © 2024 seawise Co., Ltd. All rights reserved.
    </div>
  );
};
export default FOOTER;
