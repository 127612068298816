import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Subtitle from '../components/Subtitle';
import ReturnButton from '../components/ReturnButton';
import AblogMetaData from '../components/AblogMetaData';
import AblogCustomData from '../components/AblogCustomData';
import AblogLinkedArea from '../components/AblogLinkedArea';
import DownloadButton from '../components/DownloadButton';

const FMSW021 = () => {
  const [ablogDetailData, setAblogDetailData] = useState({});
  const [hasDownloadFile, setHasDownloadFile] = useState(false);

  const location = useLocation();
  const { ablogId, historyId, officer } = location?.state ?? {};

  useEffect(() => {
    const conds = {
      imo_no: sessionStorage.getItem('selectedShip'),
      officer,
      ablog_id: ablogId,
      history_id: historyId,
    };
    axios
      .post(window.location.origin + '/api/FMSW020/getABLOGDetailData', conds)
      .then((res) => {
        setAblogDetailData(res.data);
        setHasDownloadFile(!!res.data.meta_data.ablog_template_filename);

        // ABLOG条件を保存
        sessionStorage.setItem(
          'ABLOGConds',
          JSON.stringify({ ablogId, historyId, officer })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ablogId, historyId, officer]);

  const getdownloadABLOGData = () => {
    const conds = {
      imo_no: sessionStorage.getItem('selectedShip'),
      ablog_id: ablogId,
      history_id: historyId,
      officer,
    };
    axios
      .post(window.location.origin + '/api/FMSW020/downloadABLOGData', conds)
      .then((res) => {
        const { file_path, file_name } = res.data;
        if (file_path && file_name) {
          return downloadFile(file_name, file_path);
        } else {
          alert('There is no download file.');
        }
      })
      .catch(() => {
        alert('There is no Excel File for ABLOG download.');
      });
  };

  const downloadFile = async (fileName, fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const newBlob = new Blob([blob]);
      const objUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = objUrl;
      link.download = fileName;
      link.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(objUrl);
      }, 250);
    } catch (error) {
      console.log('Download failed:', error);
    }
  };
  return (
    <>
      <Helmet>
        <title>seawise - ABLOG</title>
      </Helmet>
      <div className="sub-header">
        <Subtitle title={'View ABLOG'} officer={officer} />
        <ReturnButton url="/FMSW020" params={{ prevOfficer: officer }} />
      </div>
      <AblogMetaData metaData={ablogDetailData?.meta_data} />
      <AblogCustomData customData={ablogDetailData?.custom_data} />
      <AblogLinkedArea
        arrDepData={ablogDetailData?.arr_dep_data}
        noonReportData={ablogDetailData?.noon_report_data}
        officer={officer}
      />
      {hasDownloadFile && (
        <DownloadButton
          ButtonName={'Download ABLOG File'}
          handleClick={getdownloadABLOGData}
        />
      )}
    </>
  );
};

export default FMSW021;
