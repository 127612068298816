import React from 'react';
import { format } from 'date-fns';

const AblogMetaArea = ({ columns, metaData }) => {
  return (
    <div>
      {columns?.map((row, i) => {
        const item = row.map((column) => {
          return (
            <React.Fragment key={column.field}>
              <label className="meta-label">{column.title} :</label>
              {(column.field == 'date_from' || column.field == 'date_to') &&
              metaData != null ? (
                metaData[column.field] != null ? (
                  <label>
                    {format(metaData[column.field], 'yyyy-MM-dd HH:mm')}
                  </label>
                ) : (
                  <label>{''}</label>
                )
              ) : column.field == 'total_hour' && metaData != null ? (
                metaData[column.field] != null &&
                metaData[column.field] != '' ? (
                  <label>
                    {metaData[column.field] + '(Days-Hours:Minutes)'}
                  </label>
                ) : (
                  <label>{''}</label>
                )
              ) : column.field == 'create_date_user' && metaData != null ? (
                metaData[column.field] != null ? (
                  <label>{format(metaData[column.field], 'yyyy-MM-dd')}</label>
                ) : (
                  <label>{''}</label>
                )
              ) : column.field == 'remarks' && metaData != null ? (
                <label style={{ whiteSpace: 'pre-wrap' }}>
                  {metaData[column.field]}
                </label>
              ) : metaData != null ? (
                <label>{metaData[column.field]}</label>
              ) : (
                <label>{''}</label>
              )}
            </React.Fragment>
          );
        });

        return (
          <div
            key={i}
            className="meta-row"
            style={
              row.length == 1
                ? {
                    overflow: 'auto',
                  }
                : {}
            }
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default AblogMetaArea;
