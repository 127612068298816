import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Subtitle from '../components/Subtitle';
import ReturnMenuButton from '../components/ReturnMenuButton';
import OfficerRadioButton from '../components/OfficerRadioButton';
import ArrDepDataTable from '../components/ArrDepDataTable';

const FMSW030 = () => {
  const location = useLocation();
  const { prevOfficer = 'DECK' } = location.state ?? {};

  const [arrDepData, setArrDepData] = useState([]);
  const [officer, setOfficer] = useState(prevOfficer);
  const [isUpdateSort, setIsUpdateSort] = useState(false);

  useEffect(() => {
    const conds = {
      imo_no: sessionStorage.getItem('selectedShip'),
      officer: officer,
    };
    axios
      .post(window.location.origin + '/api/FMSW030/getArrDepData', conds)
      .then((res) => {
        setArrDepData(res.data);
        setIsUpdateSort(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [officer]);

  const sortTable = (changedUpdateSort) => {
    let tableData = [];
    if (changedUpdateSort) {
      // デフォルト => Update Date
      tableData = arrDepData.sort((a, b) => {
        if (b.update_date !== a.update_date) {
          // Sort by Update Date
          return (
            new Date(b.update_date).getTime() -
            new Date(a.update_date).getTime()
          );
        } else if (b.create_date_system !== a.create_date_system) {
          // Sort by Create Date (System)
          return (
            new Date(b.create_date_system).getTime() -
            new Date(a.create_date_system).getTime()
          );
        } else if (b.arrival_departure_id !== a.arrival_departure_id) {
          // Sort by 入出港 ID
          return b.arrival_departure_id - a.arrival_departure_id;
        } else if (b.history_id !== a.history_id) {
          // Sort by 履歴ID
          return b.history_id - a.history_id;
        }
      });
    } else {
      // Update Date => デフォルト
      tableData = arrDepData.sort((a, b) => {
        if (b.date_and_time !== a.date_and_time) {
          return (
            // Sort by Date & Time
            new Date(b.date_and_time).getTime() -
            new Date(a.date_and_time).getTime()
          );
        } else if (b.create_date_system !== a.create_date_system) {
          return (
            // Sort by Create Date (System)
            new Date(b.create_date_system).getTime() -
            new Date(a.create_date_system).getTime()
          );
        } else if (b.arrival_departure_id !== a.arrival_departure_id) {
          // Sort by 入出港 ID
          return b.arrival_departure_id - a.arrival_departure_id;
        } else if (b.history_id !== a.history_id) {
          // Sort by 履歴ID
          return b.history_id - a.history_id;
        }
      });
    }
    setArrDepData(tableData);
    setIsUpdateSort((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>seawise - Arrival / Departure</title>
      </Helmet>
      <div className="sub-header">
        <Subtitle
          title={'List of Arrival / Departure Information'}
          officer={officer}
        />
        <ReturnMenuButton />
      </div>
      <OfficerRadioButton officer={officer} setOfficer={setOfficer} />
      <ArrDepDataTable
        arrDepData={arrDepData}
        sortTable={sortTable}
        isUpdateSort={isUpdateSort}
        officer={officer}
      />
    </>
  );
};

export default FMSW030;
