const NoonDetailListTable = ({ noonDetailList, officer }) => {
  const officer_columns =
    officer == 'DECK'
      ? [
          { title: 'GMT', field: 'gmt' },
          { title: 'Position at Noon Latitude', field: 'lat' },
          { title: 'Position at Noon Longitude', field: 'lon' },
          { title: "Clock's -ah'd + ab'k", field: 'clock' },
        ]
      : [
          { title: 'Sailing Mode', field: 'sailingmode_name' },
          { title: 'Hours', field: 'hours' },
        ];
  const custom_columns = [...noonDetailList.columns];
  const columns = [
    { title: 'Creation Date', field: 'create_date_system' },
    ...officer_columns,
    { title: 'Remarks', field: 'remarks' },
    ...custom_columns,
  ];

  return (
    <>
      <div className="detail-list-area">
        <table className="detail-list-table">
          <thead>
            <tr>
              {columns.map((column) => {
                return <th key={column.field}>{column.title}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {noonDetailList.table_data.map((rowData, i) => {
              return (
                <tr key={i}>
                  {columns.map((column) => {
                    return <td key={column.field}>{rowData[column.field]}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default NoonDetailListTable;
