import AblogLinkedNoonReportDeck from './AblogLinkedNoonReportDeck';
import AblogLinkedNoonReportEng from './AblogLinkedNoonReportEng';

const AblogLinkNoonReportData = ({ linkNoonReportData, officer }) => {
  return (
    <div className="child-groupbox-container">
      <div className="groupbox-title">
        <h5 className="groupbox-label">Linked Noon Report Information</h5>
      </div>
      <div className="childgroupbox-content ">
        {officer == 'DECK' ? (
          <AblogLinkedNoonReportDeck
            linkNoonReportData={linkNoonReportData}
            officer={officer}
          />
        ) : (
          <AblogLinkedNoonReportEng
            linkNoonReportData={linkNoonReportData}
            officer={officer}
          />
        )}
      </div>
    </div>
  );
};
export default AblogLinkNoonReportData;
